<template>
    <div class="page_box">
        <img class="page_box_img" src="https://obs.pujian.com/frontend/applet/public/sys_up.png">
        <p class="page_box_title">系统正在升级中...</p>
        <div class="content-box">
            <p class="content-box-txt-le n_b">亲爱的用户： </p>
            <div class="content-box-txt-le inMobile">&emsp;&emsp;为了更好的提升客户服务体验，系统将在<span>{{ updateTime }}</span>进行系统升级，届时客户在电商或者线下的下单会受到影响，请您提前做好安排。
                系统升级结束后，将自动恢复相关业务。对由此给您带来的诸多不便，我们深表歉意，感谢您的理解与支持！</div>
            <div class="content-box-txt-le inPc">&emsp;&emsp;为了更好的提升客户服务体验，系统将在<span>{{ updateTime }}</span>进行系统升级，届时客户在电商或者线下的下单会受到影响，请您提前做好安排。</div>
            <div class="content-box-txt-le inPc">&emsp;&emsp;系统升级结束后，将自动恢复相关业务。对由此给您带来的诸多不便，我们深表歉意。</div>
            <div class="content-box-txt n_t">感谢您的理解与支持！</div>
        </div>
    </div>
</template>

<script>
window.onload = function () {
  var meta = document.createElement('meta');
  meta.name = 'viewport';
  meta.content = 'width=device-width , initial-scale=1.0';
  document.head.appendChild(meta)
}

export default {
  name: "index",
  data() {
    return {
      updateTime: '2024年8月29 (今天) 22:00 - 6:00 (次日)'
    }
  }
}
</script>

<style scoped>
    .page_box{
       padding-bottom: 170px;
        text-align: center;
    }
    .page_box_img{

    }
    .page_box_title{
        font-size: 24px;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: bold;
        color: #3875F6;
        margin-top: 36px;
    }
    .content-box{
        width: 700px;
        height: auto;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0,0,0,0.9020);
        margin: 0 auto;
        margin-top: 42px;
    }
    .content-box .content-box-txt-le{
        text-align: left;
        font-size: 18px;
    }
    .content-box .content-box-txt-le span{
        color: #E34D59;
    }
    .n_b{
        margin-bottom: 36px;
    }
    .n_t{
        margin-top: 36px;
    }
    .content-box .content-box-txt{
        text-align: center;
        font-size: 18px;
    }
    .inMobile{
        display: none;
    }
    .inPc{
        display: block;
    }
    @media screen and (max-width: 768px) {
        .page_box{

        }
        .page_box_img{
            width: 250px;
            height: 225px;
        }
        .page_box_title{
            font-size: 18px;
        }
        .content-box{
            width: auto;
            font-size: 14px;
            padding: 0 20px;
        }
        .inMobile{
            display: block;
        }
        .inPc{
            display: none;
        }
        .content-box-txt,.content-box-txt-le{
            font-size: 16px !important;
        }
    }
</style>
